<template>
  <div class="main">
    <agent-info-comp></agent-info-comp>

    <agent-sub-title>고객센터</agent-sub-title>
    <div class="content">
      <div class="main_panel">
        <div class="qa_item">
          <div class="qa">
            <div class="qt">
              <i class="fa fa-pencil" aria-hidden="true"></i> 글쓰기
            </div>
            <textarea v-model="content" rows="10" style="width: 600px"></textarea>
            <div class="btn-area">
              <button class="btn-askacno" @click="questioAcNo">계좌문의
              </button>
              <button class="btn-save-question" @click="saveQuestion()">문의하기
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="main_panel">
        <div class="qa_item" v-for="(item, index) in list" :key="index">
          <div class="qa">
            <div class="qt">
              <span>{{item.content}} </span> <span>[{{item.createTime|datef('MM월DD일 HH:mm')}}] </span>
            </div>
            <div class="as">
              <div class="qt">
                  <span v-if="item.contentReply" style="cursor: pointer">
                    <span><i class="fa fa-check" aria-hidden="true" style="color: #fff9ff;font-size: 16px"></i> 답변시간:{{item.updateTime|datef('MM월DD일 HH:mm')}}</span>
                  </span>
                <span v-else style="color: #fff9ff">
                    <i class="fa fa-share" aria-hidden="true"></i> <span>답변대기중</span>
                  </span>
              </div>
              <div class="q" v-if="item.contentReply" v-html="item.contentReply">

              </div>

            </div>

          </div>
        </div>
      </div>



    </div>
  </div>

</template>

<script>

import {Loading} from 'element-ui';
import AgentSubTitle from "../../components/agent/AgentSubTitle";
import {agentMixin} from "../../common/agent/agentMixin";
import {
  agentCustomercenterList, agentCustomercenterSave,
  agentExchangeCash, agentRechargeCash,
  doLogin,
  getAgentById,
  moveagentmoney,
  selfExchangeHistory
} from "../../network/agent/commonRequest";
import AgentInfoComp from "../../components/agent/AgentInfoComp";
import {RECEIVE_HIDE_LOADING, RECEIVE_SHOW_LOADING} from "@/store/mutation-types";
import SubTitle from "@/components/SubTitle.vue";
import Pagination from "@/components/pagenation/Pagination.vue";
import sportsConst from "@/common/sportsConst";

export default {
  name: "AgentCustomerCenter",
  components: {
    Pagination, SubTitle,
    AgentInfoComp,
    AgentSubTitle,
  },
  mixins: [agentMixin],
  data() {
    return {
      customercenter: {},
      content: null,
      list: [],
      pageNum: 1,
      pageSize: 10,
      total: 1,
      type: sportsConst.CUSTOMER_TYPE_BANK_CARD
    }
  },
  methods: {
    saveQuestion() {
      this.customercenter.content = this.content
      this.customercenter.type = this.type
      agentCustomercenterSave(this.customercenter).then(res=>{
        if (res.data.success) {
          this.content = ''
          this.$swal({
            title: '문의글 작성이 완료되였습니다',
            type: 'success',
            showCancelButton: false,
            showConfirmButton: true
          })
          this.getCusList();
        } else {
          this.$swal({
            title: res.data.msg,
            type: 'error',
            showCancelButton: false,
            showConfirmButton: true
          })
        }
      })
    },
    questioAcNo() {
      this.content = '총판 계좌번호 문의합니다'
      this.type = sportsConst.CUSTOMER_TYPE_BANK_CARD
      this.saveQuestion()
    },
    getCusList() {
      agentCustomercenterList(this.pageNum, this.pageSize).then(res => {
        this.pageTotal = res.data.total
        this.list = res.data.data;
      })
    },
    handleSizeChange(val) {
      this.pageNum = 1;
      this.pageSize = val;
      this.getCusList();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getCusList();
    },
  },
  created() {
    this.getCusList()
  }
}
</script>

<style scoped>
.main_panel {
  width: 100%;
  background-color: #111111;
}
button{
  border: transparent;
}
.btn-save-question{
  width: 100px;
  height: 30px;
  line-height: 30px;
  background: linear-gradient(180deg, #74fcf8, #1ae0da);
  color: #353539;
}
.btn-askacno{
  width: 100px;
  height: 30px;
  line-height: 30px;
  background: linear-gradient(180deg, #0c9b97, #0c807c);
  color: #fff9ff;
}

.qa_item {
  width: 100%;
  background-color: #111111;
  box-sizing: border-box;
  padding: 16px;
  border-bottom: 1px solid #616161;
}

.qa_item .qa {
  min-height: 100px;
}

.qa_item .qt {
  padding: 16px 0;
  color: #c1c1c1;
  font-weight: 600;
}

.qa_item .q {
  background-color: #4b4b4b;
  padding: 16px;
  color: #fff9ff;
  cursor: pointer;
}



.qa_item .btn-area {
  width: 100%;
  text-align: center;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  padding: 10px 0;
}
.qa_item .btn-area button{
  margin: 0 5px;
}


</style>